import { useCallback } from 'react'
import type { UpdateGuestPreferencesRequest, UpdateGuestPreferenceType, ManageReservationVenueTags } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { SectionController, CardSection } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { useManageReservationRoute } from '../../hooks'
import { useUpdateGuestPreferencesMutation } from '../../store'
import { GuestImageInput } from './GuestImageInput'
import { GuestOtherInfoInput } from './GuestOtherInfoInput'
import { guestPreferencesMessages } from './guestPreferencesMessages'
import { GuestPreferencesSelect } from './GuestPreferencesSelect'
import type { AccordionPreferenceOption } from './AccordionInput'

export interface GuestPreferencesProps {
  venueTags?: ManageReservationVenueTags
  actualId?: string
  clientId?: string
  venueId?: string
  clientPhoto?: string
  clientDisplayName?: string
  guestCount?: number
  otherInfoText?: string
  requireGdprOptin?: boolean
  showDietaryRestrictions: boolean
  showSpecialOccasions: boolean
  agreedToGdprDietaryOptin?: boolean
  champagnePreferenceTags?: string[]
  clientDietaryPreferenceTags?: string[]
  liquorPreferenceTags?: string[]
  partyDietaryPreferenceTags?: string[]
  specialOccasionTags?: string[]
  showAdditionalProfileInformation: boolean
  onError?: () => void
}

export function GuestPreferences({
  actualId,
  clientId,
  venueId,
  clientPhoto,
  clientDisplayName,
  venueTags,
  otherInfoText,
  requireGdprOptin,
  agreedToGdprDietaryOptin,
  champagnePreferenceTags,
  clientDietaryPreferenceTags,
  liquorPreferenceTags,
  partyDietaryPreferenceTags,
  specialOccasionTags,
  showSpecialOccasions,
  showDietaryRestrictions,
  showAdditionalProfileInformation,
  onError,
  guestCount = 0,
}: GuestPreferencesProps) {
  const { formatMessage } = useLocales()
  const { token, lang } = useManageReservationRoute()
  const [updateGuestPreferences] = useUpdateGuestPreferencesMutation()
  const onSave = useCallback(
    async (newGuestPreferences: Partial<UpdateGuestPreferencesRequest>) => {
      try {
        return await updateGuestPreferences({
          token: token ?? '',
          lang,
          data: newGuestPreferences,
        }).unwrap()
      } catch (e) {
        onError?.()
        return false
      }
    },
    [token, lang, updateGuestPreferences, onError]
  )
  const onTagSave = useCallback(
    async (options: AccordionPreferenceOption[], type: UpdateGuestPreferenceType) => {
      const newOptions = mapSelectedOptionsToTags(options)
      try {
        return onSave({ [type]: newOptions, ...(type === 'clientDietaryPreferenceTags' && { agreedToGdprDietaryOptin }) })
      } catch (e) {
        onError?.()
        return false
      }
    },
    [onSave, agreedToGdprDietaryOptin, onError]
  )
  const saveText = formatMessage(guestPreferencesMessages.guestPreferencesSave)

  if (!showAdditionalProfileInformation && (!venueTags || (!showSpecialOccasions && !showDietaryRestrictions))) {
    return null
  }

  return (
    <SectionController behavior="one-section-at-one-time">
      <Text data-test="guest-preferences-header" textStyle="body1Bold">
        {formatMessage(guestPreferencesMessages.guestPreferencesHeader)}
      </Text>
      <CardSection data-test="guest-preferences-section">
        {showSpecialOccasions && venueTags && specialOccasionTags && 'specialOccasionTags' in venueTags.reservationTags && (
          <GuestPreferencesSelect
            sectionName="specialOccasionTags"
            title={formatMessage(guestPreferencesMessages.guestSpecialOccasion)}
            onSave={onTagSave}
            venueTags={venueTags.reservationTags.specialOccasionTags}
            preferences={specialOccasionTags}
            saveText={saveText}
            selectType="specialOccasionTags"
            data-test="special-occasion-tags"
          />
        )}
        {showDietaryRestrictions && venueTags && (
          <>
            {(!requireGdprOptin || agreedToGdprDietaryOptin) &&
              clientDietaryPreferenceTags &&
              'dietaryPreferenceTags' in venueTags.clientTags && (
                <GuestPreferencesSelect
                  title={formatMessage(guestPreferencesMessages.guestDietaryRestriction)}
                  sectionName="clientDietaryPreferenceTags"
                  onSave={onTagSave}
                  venueTags={venueTags.clientTags.dietaryPreferenceTags}
                  preferences={clientDietaryPreferenceTags}
                  saveText={saveText}
                  selectType="clientDietaryPreferenceTags"
                  data-test="client-dietary-preference-tags"
                />
              )}
            {guestCount > 1 && partyDietaryPreferenceTags && 'dietaryPreferenceTags' in venueTags.reservationTags && (
              <GuestPreferencesSelect
                title={formatMessage(guestPreferencesMessages.partyDietaryRestriction)}
                sectionName="partyDietaryPreferenceTags"
                onSave={onTagSave}
                venueTags={venueTags.reservationTags.dietaryPreferenceTags}
                preferences={partyDietaryPreferenceTags}
                saveText={saveText}
                selectType="partyDietaryPreferenceTags"
                data-test="party-dietary-preference-tags"
              />
            )}
            {champagnePreferenceTags && 'champagnePreferenceTags' in venueTags.clientTags && (
              <GuestPreferencesSelect
                title={formatMessage(guestPreferencesMessages.guestChampagnePreference)}
                sectionName="champagnePreferenceTags"
                onSave={onTagSave}
                venueTags={venueTags.clientTags.champagnePreferenceTags}
                preferences={champagnePreferenceTags}
                saveText={saveText}
                selectType="champagnePreferenceTags"
                data-test="champagne-preference-tags"
              />
            )}
            {liquorPreferenceTags && 'liquorPreferenceTags' in venueTags.clientTags && (
              <GuestPreferencesSelect
                title={formatMessage(guestPreferencesMessages.guestLiquorPreference)}
                sectionName="liquorPreferenceTags"
                onSave={onTagSave}
                venueTags={venueTags.clientTags.liquorPreferenceTags}
                preferences={liquorPreferenceTags}
                saveText={saveText}
                selectType="liquorPreferenceTags"
                data-test="liquor-preference-tags"
              />
            )}
          </>
        )}
        {showAdditionalProfileInformation && (
          <>
            <GuestOtherInfoInput
              sectionName="guestOtherInfo"
              data-test="guest-preferences-other-info"
              saveText={saveText}
              title={formatMessage(guestPreferencesMessages.guestOtherInfo)}
              infoText={otherInfoText}
              onSave={onSave}
            />
            <GuestImageInput
              data-test="guest-preferences-image-upload"
              sectionName="guestImageInput"
              saveText={saveText}
              actualId={actualId}
              clientId={clientId}
              clientPhotoUrl={clientPhoto}
              clientDisplayName={clientDisplayName}
              venueId={venueId}
            />
          </>
        )}
      </CardSection>
    </SectionController>
  )
}

const mapSelectedOptionsToTags = (options: AccordionPreferenceOption[]) => options.filter(value => value.selected).map(value => value.id)
