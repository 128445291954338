import { useState, useCallback } from 'react'
import type { UpdateGuestPreferencesRequest } from '@sevenrooms/core/domain'
import { useSectionController } from '@sevenrooms/core/ui-kit/layout'
import { AccordionTextInput } from './AccordionInput'

export interface GuestOtherInfoInputProps {
  title: string
  sectionName: string
  onSave: (data: Partial<UpdateGuestPreferencesRequest>) => Promise<boolean>
  saveText: string
  infoText?: string
  'data-test'?: string
}

export function GuestOtherInfoInput({ infoText, sectionName, onSave, ...props }: GuestOtherInfoInputProps) {
  const { tryToggleCollapse, collapsed } = useSectionController(sectionName, { initiallyCollapsed: true })
  const [inputInfo, setInputInfo] = useState(infoText)
  const [baseInfoValue, setBaseInfoValue] = useState(infoText)
  const onAccordionTextSave = useCallback(
    async (newValue?: string) => {
      const success = await onSave({ customQuestionAnswer: newValue })
      setBaseInfoValue(newValue)
      return success
    },
    [onSave]
  )
  return (
    <AccordionTextInput
      isDirty={inputInfo !== baseInfoValue}
      onChange={setInputInfo}
      value={inputInfo}
      onSave={onAccordionTextSave}
      collapsed={collapsed}
      onToggle={tryToggleCollapse}
      {...props}
    />
  )
}
